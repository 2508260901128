import { render, staticRenderFns } from "./ListItems.vue?vue&type=template&id=33a2236c&scoped=true&"
import script from "./ListItems.vue?vue&type=script&lang=ts&"
export * from "./ListItems.vue?vue&type=script&lang=ts&"
import style0 from "./ListItems.vue?vue&type=style&index=0&id=33a2236c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33a2236c",
  null
  
)

export default component.exports